@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 224 71.4% 4.1%;
    --card: 0 0% 100%;
    --card-foreground: 224 71.4% 4.1%;
    --popover: 0 0% 100%;
    --popover-foreground: 224 71.4% 4.1%;
    /* --primary: 262.1 83.3% 57.8%; */
    --primary: 196 84% 46%;
    --primary-foreground: 210 20% 98%;
    --secondary: 220 14.3% 95.9%;
    --secondary-foreground: 220.9 39.3% 11%;
    --muted: 220 14.3% 95.9%;
    --muted-foreground: 220 8.9% 46.1%;
    --accent: 220 14.3% 95.9%;
    --accent-foreground: 220.9 39.3% 11%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 20% 98%;
    --border: 220 13% 91%;
    --input: 220 13% 91%;
    /* --ring: 262.1 83.3% 57.8%; */
    --ring: 196 84% 46%;
    --radius: 0.5rem;
    --sidebar-background: 0 0% 98%;
    --sidebar-foreground: 240 5.3% 26.1%;
    --sidebar-primary: 240 5.9% 10%;
    --sidebar-primary-foreground: 0 0% 98%;
    --sidebar-accent: 240 4.8% 95.9%;
    --sidebar-accent-foreground: 240 5.9% 10%;
    --sidebar-border: 220 13% 91%;
    --sidebar-ring: 217.2 91.2% 59.8%;
  }

  .dark {
    --background: 224 71.4% 4.1%;
    --foreground: 210 20% 98%;
    --card: 224 71.4% 4.1%;
    --card-foreground: 210 20% 98%;
    --popover: 224 71.4% 4.1%;
    --popover-foreground: 210 20% 98%;
    --primary: 263.4 70% 50.4%;
    --primary-foreground: 210 20% 98%;
    --secondary: 215 27.9% 16.9%;
    --secondary-foreground: 210 20% 98%;
    --muted: 215 27.9% 16.9%;
    --muted-foreground: 217.9 10.6% 64.9%;
    --accent: 215 27.9% 16.9%;
    --accent-foreground: 210 20% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 20% 98%;
    --border: 215 27.9% 16.9%;
    --input: 215 27.9% 16.9%;
    --ring: 263.4 70% 50.4%;
    --sidebar-background: 240 5.9% 10%;
    --sidebar-foreground: 240 4.8% 95.9%;
    --sidebar-primary: 224.3 76.3% 48%;
    --sidebar-primary-foreground: 0 0% 100%;
    --sidebar-accent: 240 3.7% 15.9%;
    --sidebar-accent-foreground: 240 4.8% 95.9%;
    --sidebar-border: 240 3.7% 15.9%;
    --sidebar-ring: 217.2 91.2% 59.8%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}

.printContent {
  display: none;

  @media print {
    display: block;
  }
}

.ql-editor {
  font-size: 14px;
  padding: 0 16px !important;
  display: flex;
  flex-direction: column;
}

.ql-editor p {
  margin: 16px 0 !important;
}

.ql-editor a {
  color: #13a5da !important;
  text-decoration: none !important;
}

.ql-editor h1 {
  font-size: 24px !important;
  font-weight: 700;
  margin: 16px 0 !important;
  line-height: 36px;
}

.ql-editor h2 {
  font-size: 20px !important;
  font-weight: 700;
  margin: 16px 0 !important;
  border-left: 2px solid #13a5da;
  padding: 12px 20px !important;
}

.ql-editor h3 {
  font-size: 18px !important;
  font-weight: 700;
  margin: 8px 0 !important;
  line-height: 36px;
  background-color: #f0f9ff;
  padding: 12px !important;
}

.ql-editor h4 {
  font-size: 18px !important;
  font-weight: 700;
  margin: 16px 0 !important;
  line-height: 52px;
  border-bottom: 1px solid #e4e4e7;
}

.ql-editor ol {
  padding-left: 0 !important;
  list-style-position: inline !important;
  margin: 16px 0 !important;
}

.ql-editor ol li > .ql-ui:before {
  font-size: 14px;
  text-align: left;
  margin-left: -12px;
  margin-bottom: 4px;
}

.ql-editor ol li {
  font-size: 14px;
  padding-left: 14px;
}

.ql-editor blockquote {
  margin: 16px 0 !important;
  background-color: #f4f4f5;
  border-left: none !important;
  padding: 20px !important;
}

.ql-editor img {
  max-width: 608px !important;
}

.ql-container {
  border-top: 0px !important;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.ql-toolbar {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.ql-emoji {
  font-size: 20px;
}

.ql-emoji img {
  display: inline-block;
}

.ql-tooltip {
  left: 0px !important;
}

.ql-formats button[disabled] {
  opacity: 0.5;
  pointer-events: none;
}
